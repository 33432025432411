import '@fontsource/roboto/latin-400.css';
import '@fontsource/roboto/latin-700.css';
import '@fontsource/roboto-slab/latin-400.css';

import { CssBaseline, makeStyles } from '@material-ui/core';
import { PageProps } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import * as reach from '@reach/router';

interface Props extends PageProps {}

const Layout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  const { pathname } = reach.useLocation();
  return (
    <React.StrictMode>
      <CssBaseline />
      <Helmet>
        <title>Ruonavaara</title>
      </Helmet>
      <div className={classes.root}>
        <main className={classes.main}>{children}</main>
      </div>
    </React.StrictMode>
  );
};

const useStyles = makeStyles((theme) => ({
  '@global': {
    html: {
      minHeight: '100%',
      display: 'flex',
    },
    'body, #___gatsby, #gatsby-focus-wrapper': {
      flex: 1,
      display: 'flex',
      background: theme.palette.background.default,
    },
    a: {
      color: '#06c',
      textDecoration: 'none',
      fontWeight: 'bold',
      display: 'inline-block',
      '&:hover': {
        '&::after': {
          transform: 'scaleX(1)',
          opacity: 1,
        },
      },
      '&::after': {
        content: '""',
        background: 'currentColor',
        height: 2,
        display: 'block',
        transition: theme.transitions.create('all', {
          duration: theme.transitions.duration.standard,
          easing: theme.transitions.easing.sharp,
        }),
        transform: 'scaleX(0)',
        opacity: 0,
      },
    },
  },
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.paper,
  },
  main: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default Layout;

import { createMuiTheme, responsiveFontSizes, Theme, ThemeOptions } from '@material-ui/core';

const fonts = {
  heading: "'Roboto Slab', 'serif'",
};

const base: ThemeOptions = {
  typography: {
    h1: {
      fontFamily: fonts.heading,
      fontWeight: 400,
      marginTop: '1em',
      marginBottom: '0.5em',
    },
    h2: {
      fontFamily: fonts.heading,
      fontWeight: 400,
      marginTop: '1em',
      marginBottom: '0.5em',
    },
    h3: {
      fontFamily: fonts.heading,
      fontWeight: 400,
      marginTop: '1em',
      marginBottom: '0.5em',
    },
    h4: {
      fontFamily: fonts.heading,
      fontWeight: 400,
      marginTop: '1em',
      marginBottom: '0.5em',
    },
    h5: {
      fontFamily: fonts.heading,
      fontWeight: 400,
      marginTop: '1em',
      marginBottom: '0.5em',
    },
    h6: {
      fontFamily: fonts.heading,
      fontWeight: 400,
      marginTop: '1em',
      marginBottom: '0.5em',
    },
    body1: {
      margin: '1em 0',
    },
  },
};

export const light = responsiveFontSizes(
  createMuiTheme({
    ...base,
    palette: {
      type: 'light',
      primary: {
        main: '#424242',
        dark: '#1b1b1b',
        light: '#6d6d6d',
        contrastText: '#fff',
      },
      secondary: {
        main: '#000',
      },
      background: {
        default: '#fafafa',
        paper: '#1e1e1e',
      },
    },
  })
);

export function getBorder(theme: Theme) {
  return theme.palette.type === 'light'
    ? {
        boxShadow: theme.palette.type === 'light' ? '#0003 0px 1px 2px 0px' : 'none',
      }
    : {
        border: theme.palette.type === 'dark' ? `thin solid ${theme.palette.divider}` : 'none',
      };
}
